@import "variables";
@import "mixins";
@import "transitions";

.ExternalLinkWarning {
  &-overlay {
    @include overlay;
    display: flex;
    justify-content: center;
    align-items: center;

    .ExternalLinkWarning-window {
      @include window;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 40em;
      min-height: 20em;
      max-height: 86vh;
      text-align: center;
      animation: fade_up 0.5s forwards;

      @include breakpoint(tablet) {
        width: 60em;
      }

      @include breakpoint(mobile) {
        width: 90%;
      }

      h2 {
        margin: 0 0 0.9em;
        color: var(--color-primary);
        font-size: 1.8rem;
      }
    }

    p {
      margin: 0.9em 0;
      line-height: 1.2;
    }

    .btn-wrapper {
      display: flex;
      gap: 1em;

      .button {
        font-size: 1.2em;
        min-width: 8em;

        &-outline {
          background: transparent;
          color: var(--color-primary);
        }
      }
    }
  }
}