@import "mixins";
@import "icons";

.Video {
  @include page;

  .overlay {
    @include overlay;
    display: flex;

    .close {
      @include button(secondary_transparent);
      @include with-icon(x);
      font-size: 2em;
      position: absolute;
      top: 0em;
      right: 0em;
      border: none;
      padding: 0;
      width: 2em;
      min-width: 0;
      height: 2em;
      line-height: 0.9;
    }

    .window {
      @include window;
      position: relative;
      margin: auto;
      border-radius: 0;
      padding: 0;
      width: 90%;
      max-width: 160vh;
      opacity: 0;
      animation: fade_up 0.4s forwards;

      .disclaimer {
        position: absolute;
        z-index: 1;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 4em;
        overflow: auto;
        background: var(--color-white);
        text-align: center;

        @include breakpoint(mobile) {
          padding: 2em;
        }

        & > div {
          opacity: 0;
          animation: fade_up 0.4s 0.4s forwards;
        }

        p {
          margin-top: 0;
          max-width: 30em;
          font-size: 1.3em;

          @include breakpoint(mobile) {
            font-size: 1.1em;
          }
        }
      }

      .player {
        width: 100%;
        padding: 56.25% 0 0 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
