@import 'breakpoints';
@import 'icons';

.ProgramNav {
  position: fixed;
  top: calc(var(--header-height) + var(--announcement-height) + 2rem);
  left: 0;
  padding-left: var(--grid-gap);
  padding-bottom: var(--grid-gap);
  width: var(--nav-width);
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  background: var(--color-white);

  @include breakpoint(mobile) {
    flex-direction: row;
    justify-content: stretch;
    gap: var(--grid-gap);
    bottom: auto;
    right: 0;
    padding-right: var(--grid-gap);
    padding-bottom: var(--grid-gap);
    width: auto;
    height: var(--program-nav-mobile-height);
  }

  .ProgramNavLink {
    @include with-icon(arrow);

    flex-grow: 1;
    flex-basis: 0;
    margin-top: var(--grid-gap);
    padding: 0.5em;
    background: #b1b1b1;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    font-size: 1.2em;
    line-height: 1.1;
    color: var(--color-white);

    @include breakpoint(mobile) {
      display: flex;
      flex-grow: auto;
      flex-basis: auto;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1rem;
      line-height: 1.1;
      padding: 0 0.5em;

      &:last-child {
        flex-basis: 10em;
      }
    }

    &::after {
      display: block;
      font-weight: bold;

      @include breakpoint(mobile) {
        display: none;
      }
    }

    span {
      display: block;
      margin-bottom: 0.3em;
      font-size: 0.7em;
      
      @include breakpoint(mobile) {
        display: none;
      }
    }

    &:hover {
      background: #999;
    }

    &.active {
      background: var(--color-primary);
    }

    &.close {
      width: 2em;
      @include with-icon(x);
      font-size: 2rem;
    }
  }
}