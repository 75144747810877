@import "variables";

.TrialGroup {
  position: relative;
  padding-top: 3em;

  &-icon {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 2em;
    width: 6em;
    height: 6em;

    img {
      display: block;
      border: 3px solid var(--color-white);
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    padding: 3em 2em 2em;
    background: var(--color-white);
  }

  &-title {
    margin-bottom: 1.5rem;
  }
}
