@import "common";
@import "breakpoints";
@import "transitions";

.App {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff url("../../assets/images/bg.jpg") no-repeat right center;
  background-size: cover;
  transition: background-position 0.4s ease-in-out;

  @include breakpoint(tablet) {
    --branding-width: 8rem;
  }

  @include breakpoint(mobile) {
    --branding-width: 5rem;
    --header-height: 3rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    padding: 1em;
  }

  &.has-announcement {
    --announcement-height: 2rem;

    @include breakpoint(mobile) {
      --announcement-height: 3rem;
    }
  }

  .App-header-bg {
    position: fixed;
    z-index: 0;
    top: var(--announcement-height);
    left: 0;
    width: 100%;
    opacity: 0;
    height: calc(var(--header-height) + 2em);
    background: var(--color-white);
    box-shadow: var(--shadow);
    transition: opacity 0.3s;

    @include breakpoint(mobile) {
      z-index: 3;
      opacity: 1;
    }
  }

  & > .Branding {
    position: fixed;
    z-index: 10;
    left: 2em;
    top: calc(var(--announcement-height) + 1em);
    width: var(--branding-width);
    height: var(--header-height);
    display: flex;
    align-items: center;
    transition: all 0.4s ease-in-out;

    @include breakpoint(mobile) {
      left: 1em;
    }

    .Logo {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  & > .Search {
    display: none;
    z-index: 11;
    top: calc(var(--announcement-height) + 1em);
    left: calc(1em + var(--branding-width) + 3vw);
    height: var(--header-height);
    transition: all 0.4s ease-in-out;

    @include breakpoint(mobile) {
      right: calc(1.5em + var(--branding-width) + 3vw) !important;
      right: 2em !important;
      left: 2em !important;
      transition: none !important;
    }
  }

  & > .Copyrights {
    position: fixed;
    z-index: 10;
    top: calc(var(--announcement-height) + 1em);
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 2em;
    width: calc((100vw - 5em) * 0.25);
    height: var(--header-height);
    font-size: 0.9em;
    text-align: right;
    transition: color 0.3s;
    overflow: visible;

    @include breakpoint(tablet) {
      width: 40vw;
    }

    p {
      margin: 0.5em 0 0;

      @include breakpoint(mobile) {
        margin: 0;
        font-size: 0.8rem;
        line-height: 1.1;

        &:not(:last-child) {
          margin-right: 1em;
        }
      }
    }

    a {
      transition: color 0.3s;
    }
  }

  &.scrolled {
    .App-header-bg {
      z-index: 3;
      opacity: 1;
    }
  }

  // Path-based styles.
  // @todo Consider moving these styles somewhere else.

  &.path--index {
    background-position-x: 40vw;

    @include breakpoint(tablet, laptop, desktop) {
      --branding-width: 15rem;
    }

    .Branding {
      @include breakpoint(tablet, laptop, desktop) {
        top: 10vh;
        left: 5vw;
      }
    }

    .Search {
      display: block;

      @include breakpoint(tablet, laptop, desktop) {
        top: 10vh;
        left: calc(8vw + var(--branding-width));
      }

      @include breakpoint(mobile) {
        &:not(.active) {
          right: 1rem !important;
          left: auto !important;
        }
      }
    }

    .Copyrights {
      color: var(--color-white);
      text-shadow: 0 0 6px var(--color-black);

      @include breakpoint(tablet, mobile) {
        display: none;
        position: fixed;
        top: auto;
        left: 1rem;
        bottom: 1rem;
        width: auto;
        height: auto;
        text-align: left;
        color: var(--color-black);
        text-shadow: 0 0 6px var(--color-white), 0 0 6px var(--color-white);
      }

      a {
        color: var(--color-white);
        text-decoration: underline;

        @include breakpoint(tablet, mobile) {
          color: var(--color-blue-1);
          text-shadow: 0 0 6px var(--color-white);
        }
      }
    }
  }

  &:not(.path--index) {
    .Copyrights {
      @include breakpoint(mobile) {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 5px rgb(0 0 0 / 20%);
        background: var(--color-white);
        padding-left: 1rem;
        width: auto;
        height: auto;
        height: 4rem;   
        text-align: left;
        font-size: 0.9em;
  
        br {
          display: none;
        }
  
        p:last-child {
          color: var(--color-gray);
          text-align: right;
        }
      }
    }
  }

  &.path--menu {

    .Search {
      display: block;
    }

    .Header {
      h1 {
        display: none;
      }

      .back {
        display: none;
      }
    }


    @include breakpoint(mobile) {
      .Header {
        right: 11rem;

        h1 {
          display: block;
        }
        
        .back {
          display: none;
        }
      }

      .Search {
        right: 1rem !important;
        left: auto !important;
        
        &:not(.active) {
          width: 9rem;

          .Search-window {
            width: 9rem;
          }
        }

        &.active {
          right: 0 !important;
        }
      }
    }
  }

  &[class*="path--trials-"] {
    & > .Branding {
      display: none;
    }
  }

  // Transitions.
  &.home-exit-active > .Menu,
  &.home-exit-done > .Menu {
    --animation-start: 0.8s;
  }

  &.path--menu,
  &.path--index,
  &.path--trials,
  &.path--approaches {
    .Copyrights {
      @include breakpoint(tablet, mobile) {
        display: flex;
      }
    }
  }
}
