@import 'icons';
@import 'breakpoints';

.GridBoxMenu {
  position: fixed;
  z-index: 1;
  border: var(--grid-gap) solid var(--color-white);
  padding: 2em;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: var(--color-white);
  font-family: var(--font-fs-albert);

  & > * {
    opacity: 0;
  }

  &.active {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 36rem !important;
    height: 17rem !important;
    max-width: 90vw !important;
    max-height: 80vh !important;
    transition: all 600ms cubic-bezier(0.575, 0.11, 0.34, 0.98);

    & > * {
      transition: opacity 0.4s;
      transition-delay: 0.4s;
      opacity: 1 !important;
    }
  }

  @include breakpoint(mobile) {
    width: auto !important;
    height: auto !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    transition: none !important;
  }

  h2 {
    font-size: 3em;
    margin: 0 0 0.7em;
    text-align: center;
    font-family: var(--font-fs-albert);
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      flex: 1;
      padding: 1em;
      min-height: 5em;
      font-size: 1.2em;
      background: var(--color-white);
      color: var(--color-black);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 1em;
      }

      .title {
        font-weight: bold;
      }
    }
  }

  .close {
    @include with-icon(x);

    position: absolute;
    top: calc(-1 * var(--grid-gap));
    right: calc(-1 * var(--grid-gap));
    border: none;
    padding: 0;
    width: 1.5em;
    height: 1.5em;
    background: var(--color-white);
    font-size: 2em;
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
    }
  }
}