@import "variables";
@import "mixins";
@import "keyframes";

.Resources {
  @include page;

  .Header {
    opacity: 0;
    animation: fade_in 1s forwards;
  }

  .list {
    padding: 0 4vw;
    max-height: 100%;
    overflow: auto;
    opacity: 0;
    animation: fade_up 0.5s 0.5s forwards;

    .columns {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin: 5vh auto;
      max-width: 80em;

      .group {
        display: inline-block;
        margin: 0 0 3em;
        width: 40%;

        @include breakpoint(mobile) {
          width: 100%;
        }
      }

      .Resource {
        display: block;
        margin-bottom: 1em;

        color: var(--color-black);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
