@import "mixins";

.Preloader {
  @include overlay;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5em;
  color: var(--color-blue-1);

  img {
    width: 10em;
    height: auto;
    margin-bottom: 1em;
  }
}
