@import "variables";
@import "mixins";
@import "keyframes";

.Trials {
  @include page;

  .Header {
    opacity: 0;
    animation: fade_in 1s forwards;
  }

  .List {
    padding: 0 4vw;
    max-height: 100%;
    overflow: auto;
    opacity: 0;
    animation: fade_up 0.5s 0.5s forwards;

    .Columns {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin: 5vh auto;
      max-width: 80em;

      @include breakpoint(mobile) {
        margin-top: 1em;
      }

      .Trials-group {
        display: inline-block;
        margin: 0 0 3em;
        width: 40%;

        @include breakpoint(mobile) {
          width: 100%;
        }
      }

      .Trials-trial {
        margin-bottom: 1em;
      }
    }
  }

  .Trials-group-modal {
    .TrialGroup {
      margin-top: -5em;

      .Trial {
        margin-bottom: 1em;
      }
    }
  }
}
