@import  'breakpoints';
@import 'icons';

@mixin gridbox-with-arrow {
  padding-right: 3.5rem;

  @include with-icon(arrow);

  &::after {
    position: absolute;
    right: 0.5em;
    top: 50%;
    width: 1em;
    height: 1em;
    color: var(--grid-box-color);
    transform: translateY(-50%);
    font-size: 1.5rem;
  }
}

.ProgramMatrix {
  position: fixed;
  top: calc(var(--header-height) + var(--announcement-height) + 2rem);
  right: 0;
  bottom: 0;
  left: var(--nav-width);
  border: none;
  padding: calc(var(--grid-gap) / 2);
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  background: var(--color-white);
  font: 600 0.9rem/1 'FSAlbert';
  -webkit-font-smoothing: antialiased;

  @include breakpoint(mobile) {
    position: static;
    display: block;
    top: calc(var(--header-height) + var(--announcement-height) + 2rem + var(--program-nav-mobile-height));
    bottom: 7.5rem;
    left: 0;
    padding-top: 0;
    font-size: 1rem;
    overflow: auto;
  }

  @include breakpoint(laptop_l) {
    font-size: 0.98rem;
  }

  @include breakpoint(tablet, laptop_s) {
    font-size: 1.2rem;
    grid-auto-rows: 10vw;
    overflow: auto;
  }

  @include breakpoint(laptop_s) {
    grid-auto-rows: 8vw;
  }

  &.matrix--target {
    .GridGroup {
      .title {
        img {
          @include breakpoint(mobile) {
            display: none;
          }
        }
  
        .thumbnail {
          @include breakpoint(mobile) {
            display: block;
          }
        }
      }
    }
  }

  &.matrix--phase {
    .GridGroup {
      .title {
        img {
          @include breakpoint (tablet, laptop, desktop) {
            display: none;
          }
        }
        .number {
          @include breakpoint (tablet, laptop, desktop) {
            display: block;
            font-size: 2.5em;
          }
        }
      }
    }
  }

  .GridBox {
    &.GridGroup {
      .title {
        position: absolute;
        left: 1em;
        top: 1em;

        @include breakpoint(mobile) {
          position: static;
          display: flex;
          align-items: center;
          min-height: 4em;
        }

        img {
          @include breakpoint(mobile) {
            margin-right: 1em;
            padding: 0.5em;
            width: 4em;
            background: var(--grid-box-color);
          }
        }

        .thumbnail {
          flex-shrink: 0;
          margin-right: 1em;
          width: 4em;
          height: 4em;
          background-size: cover;
        }

        h3 {
          @include breakpoint(mobile) {
            font-size: 1.4rem;
            line-height: 1;
            color: #515151;
            word-break: break-word;
          }
        }
        
        br {
          @include breakpoint(mobile) {
            display: none;
          }
        }
      }

      @include breakpoint(mobile) {
        @include gridbox-with-arrow;
        background: var(--color-light-gray);
        cursor: pointer;

        &::after {
          transform: translateY(-50%) rotate(90deg);
          color: var(--color-dark-gray);
        }
      }
    }

    &.CompoundGridBox {
      padding: 0.6em;
      z-index: 1;

      @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 4rem;
        font-size: 1.3em;
        background: var(--color-light-gray);
        color: #222;
      }

      &.has-compounds {
        cursor: pointer;
        
        @include breakpoint(mobile) {
          @include gridbox-with-arrow;
        }
      }

      .compounds {
        list-style: none;
        margin: 0 0 0.2em;
        padding: 0;

        .compound {
          margin: 0;

          .title {
            @include breakpoint(mobile) {
              font-weight: 600;
              display: inline;
              margin-right: 0.25em;
            }
          }

          .description {
            margin-top: 0.2em;

            @include breakpoint(mobile) {
              display: inline;
              font-size: 1em;
              color: #929396;
            }
          }
        }
      }

      &.has-multiple-compounds {
        .compound {
          & > div {
            display: inline;
          }
          .description {
            margin-left: 0.25em;
          }
          &:not(:last-child)::after {
            content: " +";
          }
        }
      }

      .description {
        font-size: 0.8em;

        @include breakpoint(mobile) {
          color: var(--grid-box-color);
        }

        p {
          margin: 0.4em 0;
          
          @include breakpoint(laptop_l) {
            margin: 0;
          }

          @include breakpoint(mobile) {
            margin-bottom: 0;
          }
        }
      }
    }

    &.grid-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
}