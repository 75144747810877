@import "variables";
@import "mixins";
@import "transitions";

.Rules {
  &-overlay {
    @include overlay;
    display: flex;
    justify-content: center;
    align-items: center;

    .Rules-window {
      @include window;
      padding: 2em 1em 2em 2em;
      animation: fade_up 0.4s forwards;

      @include breakpoint(mobile) {
        width: 90%;
      }

      .Rules-wrapper {
        padding: 2em 3em 2em 2em;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 66em;
        max-height: 86vh;
        overflow-y: auto;

        @include breakpoint(tablet) {
          width: 56em;
        }

        @include breakpoint(mobile) {
          width: 100%;
        }

        h2 {
          margin: 0 0 0.9em;
          text-align: center;
          color: var(--color-primary);
          font-size: 1.8rem;
        }

        p {
          margin: 0.9em 0;
          line-height: 1.2;
        }

        ul {
          padding-left: 1.3rem;

          li {

            &::marker {
              color: var(--color-primary);
            }

            &:not(:last-child) {
              margin-bottom: 0.5em;
            }
          }
        }

        &>.wrapper {
          margin-top: 0.9em;
          display: flex;
          align-items: flex-start;
          gap: 1em;

          &>label {
            font-size: 1.1em;
            font-family: var(--font-open-sans);
          }

          &>input[type=checkbox] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            position: relative;
            margin: 0.15em 0 0 0;
            min-width: 1.5rem;
            max-width: 1.5rem;
            min-height: 1.5rem;
            max-height: 1.5rem;
            transition: all 0.2s ease-in;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: calc(1.5rem - 2px);
              height: calc(1.5rem - 2px);
              background-color: var(--color-white);
              border-radius: 0.3rem;
              border: 1px solid var(--color-primary);
              opacity: 0.5;
            }

            &:checked::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: calc(1.5rem - 2px);
              height: calc(1.5rem - 2px);
              background-color: var(--color-primary);
              border: 1px solid var(--color-primary);
              border-radius: 0.3rem;
              opacity: 1;
            }

            &:checked::after {
              content: "";
              position: absolute;
              top: 0.45rem;
              left: 0.4rem;
              width: 0.6rem;
              height: 0.25rem;
              border-left: 2px solid var(--color-white);
              border-bottom: 2px solid var(--color-white);
              transform: rotate(306deg);
            }
          }

        }

        &>.button {
          align-self: center;
          margin-top: 1em;
          font-size: 1.2em;
          min-width: 8em;
        }
      }
    }
  }
}