@import "variables";
@import "mixins";

.Home {
  @include page;

  .page-content {
    display: flex;
    padding-left: 5vw;
    padding-top: calc(var(--header-height) + 15vh + 3em);
    flex-direction: column;
    justify-content: center;
    text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
    font: 300 1.6em/1.2 var(--font-open-sans);
    backdrop-filter: none;
    background: none;

    h1 {
      margin: 0;

      @include breakpoint(laptop) {
        font-size: 5.5rem;
      }
      
      @include breakpoint(tablet) {
        font-size: 6rem;
      }

      @include breakpoint(mobile) {
        font-size: 2em;
      }
    }

    p {
      margin: 1.5em 0;
    }

    .button {
      font-size: 1.2rem;
      text-shadow: none;
      text-align: left;

      @include breakpoint(mobile) {
        font-size: 1rem;
      }
    }
  }
}
