@import "variables";
@import "breakpoints";
@import "mixins";
@import "icons";
@import "../fonts/fonts.scss";

* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: auto;
  font: 16px/1.3 "Noto Sans", sans-serif;

  @media (max-width: 1600px) {
    font-size: 14px;
  }

  @media (max-width: 1440px) {
    font-size: 13px;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
  }
}

h1 {
  font: 300 4rem/1.2 var(--font-open-sans);
  font-weight: normal;
}

h2 {
  font: 300 2.2rem/1.2 var(--font-open-sans);
  font-weight: normal;

  @include breakpoint(tablet, mobile) {
    font-size: 2em;
  }
}

a {
  color: var(--color-blue-1);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.with-arrow {
    @include with-icon(arrow);

    &:not(.button)::after {
      display: inline-block;
      top: 0;
      margin-left: 0.2em;
      text-decoration: none;
    } 
  }
}

.button {
  @include button;

  &-white {
    @include button(white);

    &.transparent {
      @include button(white_transparent);
    }
  }

  &-primary {
    @include button(primary);
  }

  &-primary-transparent {
    @include button(primary_transparent);
  }

  &-secondry {
    @include button(secondary);
  }

  &-secondry-transparent {
    @include button(secondary_transparent);
  }

  &.with-arrow {
    @include with-icon(arrow);

    &::after {
      left: 0;
      margin-left: 0.5em;
      transition: left 0.3s;
    }

    &:not(:disabled):hover {
      &::after {
        left: 0.5em;
      }
    }
  }
}
