@import "variables";
@import "breakpoints";

.Tile {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(30px);

  .tile-content-wrap {
    opacity: 0;
    padding: 2vh 2vw;
    color: var(--color-white);

    @include breakpoint(mobile) {
      padding: 2em;
    }

    .tile-content {
      a:not(.button) {
        color: var(--color-white);
      }

      p {
        margin: 2em 0;
        line-height: 1.5;

        a:not(.button) {
          color: var(--color-blue-3);
        }
      }

      h2 {
        font: 2.5em/1.2 var(--font-noto-sans);
        margin-top: 0.5em;

        a {
          text-decoration: none;

          &.with-arrow {
            &::after {
              position: relative;
              top: 0.1em;
              left: 0;
              margin-left: 0.5em;
              line-height: 1;
              transition: left 0.3s;
            }

            &:hover {
              &::after {
                left: 0.5em;
              }
            }
          }
        }
      }

      &.tile-transparent {
        color: var(--color-black);

        a {
          color: var(--color-black);
        }
      }

      &.tile-copy {
        text-align: right;
      }
    }
  }
}
