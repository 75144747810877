@import "breakpoints";

@mixin button($style: primary) {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-radius: 3em;
  padding: 1em 1.5em;
  min-width: 10em;
  box-sizing: border-box;
  font: 600 1em/1.2 var(--font-noto-sans);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in;

  $bg-color: var(--color-primary);
  $border-color: var(--color-primary);
  $text-color: var(--color-white);

  @if $style == primary_transparent {
    $bg-color: rgba(0, 0, 0, 0);
    $border-color: var(--color-primary);
    $text-color: var(--color-primary);
  }

  @if $style == secondary {
    $bg-color: var(--color-secondary);
    $border-color: var(--color-secondary);
    $text-color: var(--color-white);
  }

  @if $style == secondary_transparent {
    $bg-color: rgba(0, 0, 0, 0);
    $border-color: var(--color-secondary);
    $text-color: var(--color-secondary);
  }

  @if $style == white {
    $bg-color: var(--color-white);
    $border-color: var(--color-white);
    $text-color: var(--color-primary);
  }

  @if $style == white_transparent {
    $bg-color: rgba(0, 0, 0, 0);
    $border-color: var(--color-white);
    $text-color: var(--color-white);
  }

  border-color: $border-color;
  background: $bg-color;
  color: $text-color;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    text-decoration: none;
  }
}

@mixin input {
  border: 1px solid var(--color-secondary);
  border-radius: 2em;
  padding: 0 2em;
  color: var(--color-secondary);
  font: 1em/4em var(--font-noto-sans);

  &::placeholder {
    color: var(--color-secondary);
    opacity: 0.75;
  }
}

@mixin radio {
  padding-top: 0.2em;
  padding-bottom: 0.2em;

  label {
    display: inline-block;
    position: relative;
    padding-left: 2em;
    cursor: pointer;
  }

  input {
    display: none;

    & + label::before,
    & + label::after {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: inline-block;
      border-radius: 50%;
      transition: opacity 0.2s ease-in;
    }

    &:checked + label::before,
    &:checked + label::after {
      opacity: 1;
    }

    & + label::before {
      width: 1.5em;
      height: 1.5em;
      border: 1px solid var(--color-blue-1);
      box-sizing: border-box;
      opacity: 0.5;
    }

    & + label::after {
      margin: 0.25em;
      width: 1em;
      height: 1em;
      background: var(--color-blue-1);
      opacity: 0;
    }
  }
}

@mixin overlay {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(30px);
}

@mixin page {
  & > .overlay {
    @include overlay;
    z-index: 0;
  }

  & > .page-content {
    width: 100%;
    min-height: 100%;
    padding-top: calc(var(--announcement-height) + var(--header-height) + 2em);
  }

  @include breakpoint(mobile) {
    padding-bottom: 4rem;
  }
}

@mixin window {
  border-radius: 2em;
  padding: 4em;
  background: var(--color-white);
  box-shadow: var(--shadow);

  @include breakpoint(mobile) {
    border-radius: 1em;
    padding: 2em;
  }
}
