@import "mixins";
@import "icons";

.Header {
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: center;
  right: calc((100vw - 5em) * 0.25 + 1em);
  top: calc(var(--announcement-height) + 1em);
  left: calc(1em + var(--branding-width) + 3vw);
  height: var(--header-height);

  @include breakpoint(mobile) {
    left: calc(1.5em + var(--branding-width) + 1rem);
    top: calc(var(--announcement-height) + 1em);
    right: 0;
    margin: 0;
    flex-direction: row-reverse
  }

  button {
    @include button(primary_transparent);
    @include with-icon(arrow_left, before);
    margin-right: 2em;
    padding: 1em 1.8em;
    min-width: 8em;
    background: var(--color-white);
    font-size: 1.1em;

    @include breakpoint(mobile) {
      @include with-icon(x, before);
      margin: 0;
      border: none;
      padding: 1rem;
      box-sizing: content-box;
      width: 2em;
      min-width: 1em;
      background: none;
    }

    &::before {
      position: relative;
      left: 0;
      margin-right: 0.5em;
      transition: left 0.2s;

      @include breakpoint(mobile) {
        left: 0 !important;
        margin: 0;
        width: 2em;
        font-size: 1.5em;
      }
    }

    &:not(:disabled):hover {
      &::before {
        left: -0.5em;
      }
    }

    span {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }

  h1 {
    margin: 0;
    color: var(--color-blue-2);
    font: normal 3em/1 var(--font-noto-sans);
    text-shadow: 0 0 20px var(--color-white);

    @include breakpoint(tablet) {
      font-size: 2em;
    }

    @include breakpoint(mobile) {
      flex: 1;
      margin-right: 0;
      font-size: 1.5em;
      line-height: 1.1;
    }
  }
}
