@import "mixins";
@import "icons";

.StaticContent {
  @include page;

  .page-content {
    position: relative;

    .window {
      @include window;
      position: relative;
      margin: 0 auto 4vh;
      width: 90%;
      max-width: 80em;
      opacity: 0;
      animation: fade_up 0.5s forwards;

      h1,
      h2,
      h3,
      h4 {
        color: var(--color-blue-1);
      }

      & > h1,
      & > h2 {
        margin-top: 0;
      }

      .close {
        @include button(secondary_transparent);
        @include with-icon(x);
        font-size: 2em;
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        border: none;
        padding: 0;
        width: 2em;
        min-width: 0;
        height: 2em;
        line-height: 0.9;
      }
    }
  }
}
