.Trial {
  & > span {
    opacity: 0.6;
  }

  & > a {
    color: var(--color-black);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
