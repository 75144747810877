@import "icons";

.Announcement {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem 0 1rem;
  border-bottom: 1px solid #f8d4ca;
  height: var(--announcement-height);
  background: var(--color-error-bg);
  font-size: 0.9em;
  line-height: 1em;
  text-align: center;
  color: var(--color-error-text);
  box-sizing: content-box;

  .close {
    @include with-icon(x);
    position: absolute;
    right: 0.25em;
    top: 0.25em;
    border: none;
    border-radius: 2em;
    padding: 0;
    width: 2em;
    height: 2em;
    font-size: 0.8rem;
    line-height: 1rem;
    background: none;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #eb9a86;
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-error-text);
  }
}
