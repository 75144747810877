@import "icons";
@import "breakpoints";

.Search-result {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
  border-radius: 2em;
  padding: 0.5em 0.5em 0.5em 1.5em;
  min-height: 3.5em;
  cursor: pointer;

  @include breakpoint(laptop, desktop) {
    padding-left: 3.5em;
  }

  .type {
    display: flex;
    align-items: center;
    margin-left: 1em;
    border: 1px solid var(--color-secondary);
    border-radius: 2em;
    padding: 0 1em;
    height: 2.5em;
    color: var(--color-secondary);
    opacity: 0.5;
    white-space: nowrap;
  }

  &:hover {
    background: #eee;

    @include breakpoint(laptop, desktop) {
      @include with-icon(arrow);

      &::after {
        position: absolute;
        left: 1.2em;
        top: 0.8em;
        font-size: 1.2em;
        color: var(--color-primary);
      }
    }

    .type {
      opacity: 1;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
