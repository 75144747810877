@import "variables";
@import "icons";
@import "breakpoints";

.DocViewer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 11;
  text-align: center;
  background: #f9f9f9;

  .DocViewer-header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    padding-right: 4em;
    border-bottom: 1px solid var(--color-gray);
    width: 100%;
    height: calc(4em + 1px);
    background: var(--color-white);
    box-shadow: var(--shadow);
    opacity: 0;
    animation: fade_down 0.3s 0.2s forwards;

    .DocViewer-close {
      @include with-icon(x);

      position: absolute;
      top: 0.1em;
      right: 0.1em;
      width: 1.8em;
      height: 1.8em;
      color: var(--color-white);
      border: none;
      border-radius: 0.1em;
      background: var(--color-primary);
      font-size: 2em;
      cursor: pointer;
    }

    .DocViewer-logo {
      position: absolute;
      left: 1em;
      top: 0.5em;
      height: 3em;
      width: auto;

      @include breakpoint(mobile) {
        display: none;
      }
    }

    .DocViewer-title {
      display: flex;
      align-items: center;
      flex: 1;
      margin: 0 0.5em 0 8em;
      font-size: 1.3em;
      line-height: 1.05em;
      color: var(--color-black);
      text-align: left;
      font-family: var(--font-noto-sans);

      @include breakpoint(mobile) {
        display: none;
      }

      & > span {
        display: block;
        max-height: 2em;
        overflow: hidden;
      }
    }

    .DocViewer-extra {
      display: flex;
      align-items: center;
      margin-right: 0.5em;

      .button {
        white-space: nowrap;
      }
    }

    .DocViewer-toolbar {
      margin-right: 0.5em;
      white-space: nowrap;

      @include breakpoint(mobile) {
        margin-right: auto;
      }

      .page-indicator {
        position: relative;
        top: -0.2em;
        margin: 0 0.5em;

        @include breakpoint(mobile) {
          display: none;
        }
      }

      button {
        margin: 0.1em 0;
        border-radius: 0.1em;
        width: 1.8em;
        height: 1.8em;
        color: var(--color-primary);
        border: none;
        background: none;
        font-size: 2em;
        cursor: pointer;

        &:hover {
          background: var(--color-light-gray);
        }

        &::after {
          font-size: 0.8em;
        }

        &.prev-page {
          @include with-icon(arrow_left);
        }

        &.next-page {
          @include with-icon(arrow);
        }

        &.zoom-in {
          @include with-icon(zoom_in);
        }

        &.zoom-out {
          @include with-icon(zoom_out);
        }

        &.download {
          @include with-icon(download);

          @include breakpoint(mobile) {
            display: none;
          }
        }
      }
    }
  }

  .DocViewer-document {
    position: absolute;
    display: flex;
    top: calc(4em + 1px);
    bottom: 0;
    width: 100%;
    overflow: auto;
    opacity: 0;
    animation: fade_in 0.5s 0.5s forwards;

    & > * {
      margin: auto;
    }

    .DocViewer-page {
      border: 1px solid var(--color-gray);
    }
  }

  .disclaimer {
    position: absolute;
    z-index: 1;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 4em;
    overflow: auto;
    background: var(--color-white);
    text-align: center;

    @include breakpoint(mobile) {
      padding: 2em;
    }

    & > div {
      opacity: 0;
      animation: fade_up 0.4s 0.2s forwards;
    }

    p {
      margin-top: 0;
      max-width: 30em;
      font-size: 1.3em;

      @include breakpoint(mobile) {
        font-size: 1.1em;
      }
    }

    .button {
      margin: 1em;
    }
  }
}
