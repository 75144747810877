@import "mixins";
@import "icons";

.Modal {
  &-overlay {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 5rem 0 2rem;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(30px);
    z-index: 99; // Auth is 100

    .Modal.is-open & {
      display: flex;
    }
  }

  &-logo {
    position: fixed;
    left: 2em;
    top: 1.5rem;
    width: var(--branding-width);
    height: auto;
    display: flex;
    align-items: center;
    opacity: 0;
    animation: fade_in 0.5s 0.5s forwards;
  }

  &-header {
    position: fixed;
    z-index: 3;
    left: calc(1em + var(--branding-width) + 3vw);
    top: 2rem;
    margin: 0 1em 0 0;
    color: var(--color-blue-1);
    font: normal 2em/1.2 var(--font-noto-sans);
    opacity: 0;
    animation: fade_in 0.5s 0.5s forwards;
  }

  & > .Modal-close {
    @include with-icon(x);

    position: absolute;
    top: 0.1em;
    right: 0.1em;
    width: 1.8em;
    height: 1.8em;
    color: var(--color-white);
    border: none;
    border-radius: 0.1em;
    background: var(--color-primary);
    font-size: 2em;
    cursor: pointer;
  }

  &-window {
    position: relative;
    background: var(--color-white);
    padding: 2em;
    box-shadow: var(--shadow);
    opacity: 0;
    animation: fade_up 0.5s 0.5s forwards;

    .Modal-close {
      @include with-icon(x);
  
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      padding: 0;
      width: 2em;
      height: 2em;
      color: var(--color-primary);
      border: none;
      background: none;
      font-size: 2em;
      cursor: pointer;
    }
  }
}
