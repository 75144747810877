@import "variables";
@import "mixins";
@import "transitions";

@keyframes shakeform {
  25% {
    transform: translateX(-20px);
  }

  75% {
    transform: translateX(-0px);
  }

  100% {
    transform: translateX(0);
  }
}

.Auth {
  &-overlay {
    @include overlay;
    display: flex;
    justify-content: center;
    align-items: center;

    .Auth-window {
      @include window;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 3em;
      width: 64em;
      max-width: 90%;
      min-height: 40em;
      max-height: 80%;
      text-align: center;

      &--password {
        width: 50em;
        max-height: 86vh;

        &.shake {
          animation: shakeform .2s cubic-bezier(.19, .49, .38, .79) both;
          animation-iteration-count: 3;
          transform: translateX(0);
        }

        &>.Branding {
          top: auto;
          left: auto;

          @include breakpoint(mobile) {
            &>a {
              &>img {
                width: 150px;
              }
            }
          }
        }

        &>.Auth-form {
          &>h2 {
            margin: 0 0 0.9em;
            color: var(--color-primary);
            font-size: 1.7rem;
            word-break: break-word;
          }

          &>.wrapper {
            margin-top: 0.9em;
            max-width: 90%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 1em;
            margin: 2em auto;

            @include breakpoint(mobile) {
              max-width: 100%;
            }

            &>label {
              font-family: var(--font-open-sans);
              font-size: 1.1em;
              text-align: left;
            }

            &>input[type=checkbox] {
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              position: relative;
              margin: 0.15em 0 0 0;
              min-width: 1.3rem;
              max-width: 1.3rem;
              min-height: 1.3rem;
              max-height: 1.3rem;
              transition: all 0.2s ease-in;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: calc(1.5rem - 2px);
                height: calc(1.5rem - 2px);
                background-color: var(--color-white);
                border-radius: 0.3rem;
                border: 1px solid var(--color-primary);
                opacity: 0.5;
              }

              &:checked::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: calc(1.5rem - 2px);
                height: calc(1.5rem - 2px);
                background-color: var(--color-primary);
                border: 1px solid var(--color-primary);
                border-radius: 0.3rem;
                opacity: 1;
              }

              &:checked::after {
                content: "";
                position: absolute;
                top: 0.45rem;
                left: 0.35rem;
                width: 0.65rem;
                height: 0.3rem;
                border-left: 2px solid var(--color-white);
                border-bottom: 2px solid var(--color-white);
                transform: rotate(306deg);
              }
            }
          }

          &>.warpper-password {
            max-width: 90%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;

            @include breakpoint(mobile) {
              max-width: 100%;
            }

            &>label {
              margin-bottom: 0.75em;
              padding-left: calc(1em + 1.3rem);
              align-self: flex-start;
              font-size: 1.1em;
            }

            &>input {
              border-radius: 2em;
              border: 1px solid var(--color-primary);
              font: 1.3em/2.7em Verdana, Geneva, sans-serif;
              letter-spacing: 0.3em;
              padding: 0 calc(1em + 1.3rem);
              opacity: 0.5;

              &:focus {
                outline: var(--color-primary);
                opacity: 1;
              }
            }
          }

          input[type="submit"] {
            margin-top: 2em;
            font-size: 1.2em;
            min-width: 8em;

            @include breakpoint(mobile) {
              margin-top: 1em;
            }
          }
        }
      }

      &>.fade-exit {
        position: absolute;
        left: 0;
        width: 100%;
      }

      form,
      .Auth-redirect-message {
        &>h2 {
          margin: 0 0 1.5em;
          font-size: 1.8rem;
        }

        &>p {
          margin: 2em 0;
          font: 300 1.5em/1.2 var(--font-open-sans);
        }

        .radio {
          @include radio;
          display: block;
          margin: 0 auto 1em;
          font-size: 1.24em;
          text-align: left;
        }

        input[type="submit"] {
          margin-top: 2em;
          font-size: 1.2em;
          min-width: 8em;

          @include breakpoint(mobile) {
            margin-top: 1em;
          }
        }

        &.Auth-form-hcp {
          .radio {
            padding-left: 4em;
            max-width: 28em;

            @include breakpoint(mobile) {
              max-width: 20em;
              margin-left: auto;
              margin-right: auto;
              padding-left: 0;
            }
          }
        }

        &.Auth-form-geo {
          .Auth-choices {
            column-count: 2;
            margin: 0 auto;
            padding-left: 4em;
            max-width: 32em;
            min-height: 14em;

            @include breakpoint(mobile) {
              column-count: unset;
              display: flex;
              flex-wrap: wrap;
              padding-left: 0;
            }

            .radio {
              margin: 0 0 1em;

              @include breakpoint(mobile) {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}