@import "variables";
@import "mixins";
@import "breakpoints";
@import "keyframes";

.Menu {
  @include page;
  --animation-start: 0.1s;
  overflow: hidden;

  .overlay {
    opacity: 0;
    animation: fade_in 0.5s calc(var(--animation-start) + 0.5s) forwards;
  }

  .Menu-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "branding branding trials copy"
      "program program trials approaches"
      "program program trials approaches"
      "information information publications publications";
    column-gap: 1em;
    row-gap: 1em;
    padding: calc(var(--announcement-height) + 1em) 1em 1em;
    position: relative;
    min-height: 100vh;

    @include breakpoint(tablet) {
      padding-top: calc(
        var(--announcement-height) + var(--header-height) + 3em
      );
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "program program"
        "trials approaches"
        "publications information";
    }

    @include breakpoint(mobile) {
      padding: 0;
      padding-top: calc(
        var(--announcement-height) + var(--header-height) + 2em
      );
      grid-template-columns: 1fr;
      grid-template-areas:
        "program"
        "trials"
        "approaches"
        "publications"
        "information"
        "contact";
    }

    .Tile {
      opacity: 0;
      animation: fade_in 0.5s var(--animation-start) forwards;

      $tiles: branding, trials, copy, program, approaches, information,
        publications, contact;

      @each $tile in $tiles {
        &.tile-#{$tile} {
          grid-area: $tile;
        }
      }

      &.tile-branding {
        img {
          width: 80%;
          max-width: 10em;
        }
      }

      .tile-content-wrap {
        min-height: 100%;
        min-width: 100%;
        opacity: 0;
        transform: translateY(30%);
        animation: fade_up 0.5s calc(var(--animation-start) + 0.5s) forwards;

        .tile-content {
          opacity: 0;
          animation: fade_up 0.5s calc(var(--animation-start) + 1s) forwards;
        }
      }
    }
  }
}
