@font-face {
  font-family: "Icons";
  src: url("../fonts/icons/icons.ttf") format("truetype"),
    url("../fonts/icons/icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin with-icon($glyph, $position: "after") {
  $glyphs: (
    arrow: "r",
    arrow_left: "l",
    search: "s",
    x: "x",
    warinig: "w",
    zoom_in: "i",
    zoom_out: "o",
    download: "d",
  );

  &::#{$position} {
    position: relative;
    top: 0.1em;
    font-family: "Icons";
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: map-get($glyphs, $glyph);
  }
}
