:root {
  --color-white: #fff;
  --color-black: #000;
  --color-light-gray: #eee;
  --color-gray: #bbb;
  --color-dark-gray: #646464;
  --color-primary: #0b00d2;
  --color-secondary: #0037ec;
  --color-blue-1: #011ac9;
  --color-blue-2: #000d84;
  --color-blue-3: #c7ecff;
  --color-blue-4: #5e85ea;
  --color-error-bg: #f9e1db;
  --color-error-text: #a51b00;

  --font-noto-sans: "Noto Sans", sans-serif;
  --font-open-sans: "Open Sans", sans-serif;
  --font-fs-albert: "FSAlbert", sans-serif;

  --shadow: 0 0 30px rgba(100, 100, 100, 0.5);

  --announcement-height: 0px;
  --branding-width: 7rem;
  --header-height: 10vh;

  --transition-duration: 0.5s;

  --program-nav-mobile-height: 4rem;
  --grid-box-color: #aaa;
}
