.fade-enter {
  opacity: 0;

  &-active {
    opacity: 1;
    transition: opacity var(--transition-duration);
  }
}

.fade-exit {
  opacity: 1;
  transition: opacity var(--transition-duration);

  &-active {
    opacity: 0;
  }

  &-done {
    display: none;
  }
}
