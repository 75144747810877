@import "mixins";
@import "keyframes";

.Program {
  @include page;

  --grid-gap: 0.4rem;
  --nav-width: 9rem;

  .Header {
    opacity: 0;
    animation: fade_in 1s forwards;
  }

  .page-content {
    position: relative;
  }

  .Overlay {
    position: fixed;
    top: calc(var(--header-height) + var(--announcement-height) + 2rem + var(--grid-gap) / 2);
    bottom: 0;
    height: auto;
    animation: fade_in 0.5s;
  }

  .Program-content {
    @include breakpoint(mobile) {
      position: fixed;
      top: calc(var(--header-height) + var(--announcement-height) + 2rem + var(--program-nav-mobile-height));
      bottom: 4rem;
      left: 0;
      right: 0;
      flex-direction: column;
      justify-content: stretch;
      font-size: 1rem;
      overflow: auto;
    }

    .Program-footer {
      position: fixed;
      top: calc(var(--header-height) + var(--announcement-height) + 2rem  + 50vh);
      bottom: 0;
      left: 0;
      border: var(--grid-gap) solid var(--color-white);
      border-top: none;
      border-right: none;
      padding: 0 0.5rem;
      font-size: 11px;
      letter-spacing: -0.5px;
      color: var(--color-dark-gray);
      line-height: 1.1;
      width: var(--nav-width);
      background: var(--color-light-gray);
      overflow-y: auto;
      overflow-x: hidden;
  
      @include breakpoint(mobile, laptop_s, tablet) {
        font-size: 10px;
      }
  
      @include breakpoint(mobile) {
        position: static;
        width: 100%;
        box-sizing: border-box;
        top: auto;
        right: 0;
        bottom: 0;
        border-right: var(--grid-gap) solid var(--color-white);
        width: auto;
      }
  
      &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
      }
  
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: var(--color-gray);
      }
  
      hr {
        border: none;
        border-top: 1px solid #ddd;
  
        @include breakpoint(mobile) {
          display: none;
        }
      }
  
      p {
        @include breakpoint(mobile) {
          margin: 0.5em 0;
        }
      }
    }
  }
}
